import React from "react";

const ContactUs = () => {
  return (
    <div class="education-main-section">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="education-contact-us">
              <ul>
                <li>
                  <h6>Phone</h6>
                  <span>028 9013 6332 - +44 7507272479 - +44 7922299856</span>
                  <i class="fa fa-phone"></i>
                </li>

                <li>
                  <h6>Email</h6>
                  <a href="mailto:info@guidanceplusni.com">info@guidanceplusni.com</a>
                  <i class="fa fa-envelope"></i>
                </li>
                <li>
                  <h6>Location</h6>
                  <span>115 Cregagh Road Belfast BT6 0LA 028 9013 6332</span>
                  <i class="fa fa-map-marker"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-9">
            <div class="education-contact-us-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1012.726973165517!2d-5.899368823267618!3d54.58231391440904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48610910df1959e1%3A0x4ec962eaf9e8cf35!2s115%20Cregagh%20Rd%2C%20Belfast%20BT6%200LA%2C%20UK!5e0!3m2!1sen!2sin!4v1726836014408!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="contact-map"
              ></iframe>
            </div>
          </div>
          <div class="col-md-12">
            <div class="education-fancy-title">
              <i class="fa fa-graduation-cap"></i>
              <span>get it touch</span>
              <h2>message now</h2>
            </div>
            <div class="education-contact-us-form">
              <form>
                <ul>
                  <li>
                    <input
                      type="text"
                      value="Name"
                      onblur="if(this.value == '') { this.value ='Name'; }"
                      onfocus="if(this.value =='Name') {    this.value = ''; }"
                    />
                    <i class="fa fa-user"></i>
                  </li>
                  <li>
                    <input
                      type="text"
                      value="website"
                      onblur="if(this.value == '') { this.value ='website'; }"
                      onfocus="if(this.value =='website') {    this.value = ''; }"
                    />
                    <i class="fa fa-globe"></i>
                  </li>
                  <li class="message-box">
                    <textarea name="comment" placeholder="Message"></textarea>
                  </li>
                  <li>
                    <label>
                      <input type="submit" value="Send" />
                    </label>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
